import * as THREE from 'three';
import gsap from 'gsap';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// Créatio de la scene
const scene = new THREE.Scene();
// variable pour la couleur
let fogColor = new THREE.Color(0x000)
// couleur de fon
scene.background = fogColor;
// fog = brouillard a mettre pouur l'effet brouillard
//scene.fog = new THREE.Fog(fogColor, 0.25, 200)
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth / 1.5, window.innerHeight / 1.5, false);
document.body.appendChild(renderer.domElement);

// Création de sphere pour un planisphere
// couleur sphere
const material = new THREE.MeshLambertMaterial({ color: 0xffffff });
// Ma class de Spheres
class Sphere {
    constructor(radius, widthSegments, heightSegments, phiStart, phiLength, thetaStart, thetaLength) {
        this.radius = radius;
        this.widthSegments = widthSegments;
        this.heightSegments = heightSegments;
        this.phiStart = phiStart;
        this.phiLength = phiLength;
        this.thetaStart = thetaStart;
        this.thetaLength = thetaLength;
        this.mat = material
        this.geo = new THREE.SphereGeometry(this.radius, this.widthSegments, this.heightSegments, this.phiStart, this.phiLength, this.thetaStart, this.thetaLength);
        this.mesh = new THREE.Mesh(this.geo, this.mat);
    }
    rotation() {
        this.mesh.rotation.y += .005
        this.mesh.rotation.x += .005
    }
}

const etoiles = [];
// group pour les sphere
const group = new THREE.Group();
const group2 = new THREE.Group();
// boucle qui instancie toutes les spheres

for (let i = 0; i < 1000; i++) {
    // group
    // on crée les spheres
    let item = new Sphere(Math.random() * 5, 8, 5);
    // position random de -x à x et -y à y
    item.mesh.position.set(Math.random() * 500 - 250, Math.random() * 500 - 250, Math.random() * 500 - 250);
    etoiles.push(item)
    // on ajoute au groupe les sphere crée
    group.add(item.mesh);
}

for (let i = 0; i < 1000; i++) {
    // group2
    // on crée les spheres
    let item = new Sphere(Math.random() * 5, 6, 4);
    // position random de x à -x et y à -y
    item.mesh.position.set(Math.random() * 500 - 250, Math.random() * 500 - 250, Math.random() * 500 - 250);
    // push dans l'array
    etoiles.push(item)
    // on ajoute au groupe les sphere crée
    group2.add(item.mesh);
}

//------------ Astre au centre -----------------------
//const sun = new THREE.SphereGeometry(10, 32, 32);
const sun = new THREE.BoxGeometry(15, 15, 15);
// couleur
const materialSun = new THREE.MeshPhongMaterial({ color: 0xffd000, wireframe: false });
const sphereSun = new THREE.Mesh(sun, materialSun);

group.add(sphereSun)

scene.add(group, group2);

// création de la lumière sur un points précis
const light = new THREE.PointLight(0xffffff, 5, 200);
light.position.set(0, 0, 0);
const light2 = new THREE.PointLight(0xffffff, 5, 200);
light2.position.set(10, 10, 5);
scene.add(light, light2);
// permet de gere le control avec la souris
// bien l'importer depuis les node_modules
const controls = new OrbitControls(camera, renderer.domElement);
// position de la camera
camera.position.z = 250;
// function de gestion du mouvement 
function animate() {
    requestAnimationFrame(animate);
    //----------SUN---------------
    sphereSun.rotation.y += 0.003;
    sphereSun.rotation.x += 0.003;
    // faire tourner toutes mes spheres sur leur propre axe y
    for (let sphere in etoiles) {
        etoiles[sphere].rotation()
    }

    // rotation des spheres 
    group2.rotation.y -= .002
    group2.position.set(0, 0, 0)

    group.rotation.y += .002
    group.position.set(0, 0, 0)

    // de mise a jour des controls
    controls.update();
    renderer.render(scene, camera);
};

animate();